import {WASH_AND_FOLD_SUBCATEGORY} from "~constants/index";

export const sortCategoryServices = (category) => {
  category.services.sort((a, b) => {
    const nameA = a.name.toLowerCase(),
      nameB = b.name.toLowerCase();
    if (nameA < nameB) {
      //sort string ascending
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0; //default return value (no sorting)
  });
};

export const swapServiceCategories = ({
  serviceList,
  exisingService,
  updatedService,
  newServiceCategoryId,
}) => {
  const prevCategoryIndex = serviceList.findIndex(
    (category) => category.id === exisingService.serviceCategoryId
  );
  const newCategoryIndex = serviceList.findIndex(
    (category) => category.id === newServiceCategoryId
  );
  if (prevCategoryIndex !== -1 && newCategoryIndex !== -1) {
    // remove service from prev category
    serviceList[prevCategoryIndex] = {
      ...serviceList[prevCategoryIndex],
      services: serviceList[prevCategoryIndex].services.filter(
        (service) => service.id !== exisingService.id
      ),
    };
    // add service to new category
    serviceList[newCategoryIndex] = {
      ...serviceList[newCategoryIndex],
      services: [...serviceList[newCategoryIndex].services, updatedService],
    };
  }
};

export const updateService = ({
  serviceList,
  updatedService,
  serviceCategoryId,
  serviceId,
}) => {
  const categoryIndex = serviceList.findIndex(
    (category) => category.id === serviceCategoryId
  );
  if (categoryIndex !== -1) {
    const serviceIndex = serviceList[categoryIndex].services.findIndex(
      (service) => service.id === serviceId
    );
    if (serviceIndex !== -1) {
      serviceList[categoryIndex].services = [...serviceList[categoryIndex].services];
      serviceList[categoryIndex].services[serviceIndex] = updatedService;
    }
  }
};

export const updateServicePriceByLocation = ({service, storeId, field, value}) => {
  return {
    ...service,
    prices: service.prices.map((price) =>
      price.storeId === storeId ? {...price, [field]: value} : price
    ),
  };
};

export const updateServicePriceByLocationLegacy = ({service, storeId, field, value}) => {
  return {
    ...service,
    allPrices: service.allPrices.map((price) =>
      price.storeId === storeId ? {...price, [field]: value} : price
    ),
  };
};

export const updateServiceInList = ({
  servicesList,
  serviceCategoryId,
  serviceId,
  updater,
  data,
}) => {
  return servicesList.map((category) => {
    if (category.id === serviceCategoryId) {
      return {
        ...category,
        services: category.services.map((service) =>
          service.id === serviceId
            ? updater({
                service,
                ...data,
              })
            : service
        ),
      };
    } else {
      return category;
    }
  });
};

export const updateServiceListPriceByLocation = ({
  servicesList,
  serviceCategoryId,
  serviceId,
  storeId,
  field,
  value,
}) => {
  return updateServiceInList({
    servicesList,
    serviceCategoryId,
    serviceId,
    updater: updateServicePriceByLocation,
    data: {storeId, field, value},
  });
};

export const updateServiceListPriceByLocationLegacy = ({
  servicesList,
  serviceCategoryId,
  serviceId,
  storeId,
  field,
  value,
}) => {
  return updateServiceInList({
    servicesList,
    serviceCategoryId,
    serviceId,
    updater: updateServicePriceByLocationLegacy,
    data: {storeId, field, value},
  });
};

export const setServiceListPrice = ({
  servicesList,
  serviceCategoryId,
  serviceId,
  price,
}) => {
  return updateServiceInList({
    servicesList,
    serviceCategoryId,
    serviceId,
    updater: setOrAddServicePrice,
    data: {price},
  });
};

export const setServiceListPriceLegacy = ({
  servicesList,
  serviceCategoryId,
  serviceId,
  price,
}) => {
  return updateServiceInList({
    servicesList,
    serviceCategoryId,
    serviceId,
    updater: setOrAddServicePriceLegacy,
    data: {price},
  });
};

export const setOrAddServicePrice = ({service, price}) => {
  const prices = [...service.prices];

  const priceIndex = prices.findIndex((_price) => _price.id === price.id);
  if (priceIndex !== -1) {
    prices[priceIndex] = price;
  } else {
    prices.push(price);
  }

  return {
    ...service,
    prices,
  };
};

export const setOrAddServicePriceLegacy = ({service, price}) => {
  const allPrices = [...service.allPrices];

  const priceIndex = allPrices.findIndex((_price) => _price.id === price.id);
  if (priceIndex !== -1) {
    allPrices[priceIndex] = price;
  } else {
    allPrices.push(price);
  }

  return {
    ...service,
    allPrices,
  };
};

export const applyPriceToIds = ({service, pricesIdsToUpdate, referencePrice}) => {
  return {
    ...service,
    prices: service.prices.map((price) => {
      if (pricesIdsToUpdate.includes(price.id)) {
        return {
          ...price,
          minPrice: Number(referencePrice.minPrice),
          minQty: Number(referencePrice.minQty),
          storePrice: Number(referencePrice.storePrice),
          isTaxable: referencePrice.isTaxable,
        };
      } else {
        return price;
      }
    }),
  };
};

export const applyPriceToIdsLegacy = ({service, pricesIdsToUpdate, referencePrice}) => {
  return {
    ...service,
    allPrices: service.allPrices.map((price) => {
      if (pricesIdsToUpdate.includes(price.id)) {
        return {
          ...price,
          storePrice: Number(referencePrice.storePrice),
        };
      } else {
        return price;
      }
    }),
  };
};

export const applyPriceToIdsInList = ({
  servicesList,
  serviceCategoryId,
  serviceId,
  pricesIdsToUpdate,
  referencePrice,
}) => {
  return updateServiceInList({
    servicesList,
    serviceCategoryId,
    serviceId,
    updater: applyPriceToIds,
    data: {pricesIdsToUpdate, referencePrice},
  });
};

export const applyPriceToIdsInListLegacy = ({
  servicesList,
  serviceCategoryId,
  serviceId,
  pricesIdsToUpdate,
  referencePrice,
}) => {
  return updateServiceInList({
    servicesList,
    serviceCategoryId,
    serviceId,
    updater: applyPriceToIdsLegacy,
    data: {pricesIdsToUpdate, referencePrice},
  });
};

export const getMappedItemCategoryLabel = (category) => {
  return category === WASH_AND_FOLD_SUBCATEGORY
    ? "Wash & Fold"
    : category === "FIXED_PRICE"
    ? "Fixed Price"
    : category;
};
