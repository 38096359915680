import _ from "lodash";
import sortBy from "lodash/sortBy";

import {curateShiftsAndTimings} from "~components/business-owner/global-settings/locations/utils/location";

import actionTypes from "~actionTypes";
import {servicesAndProductsTabValues} from "~constants";
import type {TaxRate} from "~types/businesses/TaxRate";
import type {DeliverySettings} from "~types/deliveries/DeliverySettings";
import type {District} from "~types/districts/District";
import type {Region} from "~types/districts/Region";
import type {ExtendedIndividualLocation, Location} from "~types/locations/Location";
import type {ImpactedOrders, StoreClosure} from "~types/locations/StoreClosure";
import type {ServiceCategoryWithServices} from "~types/services/ServiceCategory";
import type {ShiftWithTimings} from "~types/shifts/Shift";
import {createNamespacer, createReducer} from "~utils/reducers";

const BoGlobalSettingsNamespacer = createNamespacer("BUSINESS_OWNER_GS_LOCATIONS");

const sortAscByName = (values) => {
  return sortBy(values, [(value) => value.name.toLowerCase()]);
};

export interface LocationsState {
  list: Location[];
  needsRegions: boolean;
  showFullPageError: boolean;
  fullServiceError: string;
  selectedLocation: ExtendedIndividualLocation | null;
  isLocationCallInProgress: boolean;
  showShiftsScreen: boolean;
  isShiftsCallInProgress: boolean;
  selectedLocationShiftsData: {
    success: boolean;
    storeId: string; // string returned from the API
    shifts: ShiftWithTimings[];
  } | null;
  unTouchedSelectedLocationShiftsData: {
    success: boolean;
    storeId: string; // string returned from the API
    shifts: ShiftWithTimings[];
  } | null;
  selectedShiftIndex: number;
  shiftsError: string;
  shiftsUpdateOrCreateError: string;
  districts: District[];
  districtsCallInProgress: boolean;
  refreshLocations: boolean;
  regions: Region[];
  regionsCallInProgress: boolean;
  locationsWithOutHub: Location[];
  regionsWithOutHub: Region[];
  isWithOutHubCallInProgress: boolean;
  showServicePricesScreen: boolean;
  showProductPricesScreen: boolean;
  servicesError: string;
  isServiceCallInProgress: boolean;
  activeLocationServices: ServiceCategoryWithServices[];
  isLocationDetailsLoading: boolean;
  locationDetailsError: string;
  refreshLocationDetails: boolean;
  activeServicesAndProductsTab: string;
  servicePriceUpdateError: string;
  isDetailsCallInProgress: boolean;
  saveLocationCallInProgress: boolean;
  showSaveLocationScreen: boolean;
  addLocationStep: number;
  errorMessage: string;
  isEdit: boolean;
  newTaxRateWhileLocationCreation: TaxRate | null;
  showAddTaxScreen: boolean;
  taxRatesList: TaxRate[];
  newTaxRateCallInprogress: boolean;
  taxErrorMessage: string;
  taxUpdateCallInProgress: boolean;
  processingUpdateCallInProgress: boolean;
  showEsdRegistrationScreen: boolean;
  esdErrorMessage: string | null;
  showResetPasswordScreen: boolean;
  resetPasswordApiError: string;
  resetPasswordLoading: boolean;
  showCheckedInEmployees: boolean;
  fetchCheckedInEmployeesApiError: string;
  fetchCheckedInEmployeesLoading: boolean;
  checkedInEmployees: string[]; // array of fullnames
  showStoreClosuresScreen: boolean;
  storeClosuresApiError: string;
  storeClosuresApiLoading: boolean;
  storeClosures: StoreClosure[];
  impactedOrders: Partial<ImpactedOrders>;
  deliveryWizard: string;
  editDeliverySettingsScreenType: string;
  deliverySettings: DeliverySettings | null;
  showDeliverySettingsScreen: boolean;
  deliverySettingsApiError: string;
  deliverySettingsLoading: boolean;
}

const initialState: LocationsState = {
  list: [],
  needsRegions: false,
  showFullPageError: false,
  fullServiceError: "",

  selectedLocation: null, // id of the selected location

  isLocationCallInProgress: false,
  showShiftsScreen: false,
  isShiftsCallInProgress: false,
  // This will be used for holding all user changes
  selectedLocationShiftsData: null,
  // This will be set only on GET call from server
  unTouchedSelectedLocationShiftsData: null,
  selectedShiftIndex: 0,
  shiftsError: "",
  shiftsUpdateOrCreateError: "",
  districts: [],
  districtsCallInProgress: false,
  refreshLocations: false,
  regions: [],
  regionsCallInProgress: false,
  locationsWithOutHub: [],
  regionsWithOutHub: [],
  isWithOutHubCallInProgress: false,
  showServicePricesScreen: false,
  showProductPricesScreen: false,
  servicesError: "",
  isServiceCallInProgress: false,
  activeLocationServices: [],
  isLocationDetailsLoading: false,
  locationDetailsError: "",
  refreshLocationDetails: false,
  activeServicesAndProductsTab: servicesAndProductsTabValues.PER_POUND,
  servicePriceUpdateError: "",
  isDetailsCallInProgress: false,

  // Add or update Location Form And Steps
  saveLocationCallInProgress: false,
  showSaveLocationScreen: false,
  addLocationStep: 1,
  errorMessage: "",
  isEdit: false,
  newTaxRateWhileLocationCreation: null,

  // Tax related fields.
  showAddTaxScreen: false,

  // Fetch all taxes
  taxRatesList: [],

  // Add tax rate
  newTaxRateCallInprogress: false,
  taxErrorMessage: "",

  // Add tax rate to selected location
  taxUpdateCallInProgress: false,

  // Change processing step
  processingUpdateCallInProgress: false,

  // ESD registration screen
  showEsdRegistrationScreen: false,
  esdErrorMessage: null,

  // Reset Password Screen
  showResetPasswordScreen: false,
  resetPasswordApiError: "",
  resetPasswordLoading: false,

  //Checked In Employee List
  showCheckedInEmployees: false,
  fetchCheckedInEmployeesApiError: "",
  fetchCheckedInEmployeesLoading: false,
  checkedInEmployees: [],

  //Store Closures Screen
  showStoreClosuresScreen: false,
  storeClosuresApiError: "",
  storeClosuresApiLoading: false,
  storeClosures: [],
  impactedOrders: {},

  // Delivery Settings
  deliveryWizard: "",
  editDeliverySettingsScreenType: "",

  deliverySettings: null,
  showDeliverySettingsScreen: false,
  deliverySettingsApiError: "",
  deliverySettingsLoading: false,
};

const handlers = {
  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.SET_LOCATION_LIST
  )]: (state, action) => {
    return {
      ...state,
      list: action.payload.allLocations,
      needsRegions: action.payload.needsRegions,
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.SET_LOCATION_ERROR
  )]: (state, action) => {
    return {
      ...state,
      showFullPageError: action.payload.showFullPageError,
      errorMessage: action.payload.errorMessage,
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.RESET_LOCATION_ERROR
  )]: (state) => {
    return {
      ...state,
      showFullPageError: initialState.showFullPageError,
      errorMessage: initialState.errorMessage,
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.APPEND_TO_LOCATION_LIST
  )]: (state, action) => {
    const updatedLocationList = state.list.slice();
    updatedLocationList.push(action.payload);
    return {
      ...state,
      list: updatedLocationList,
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.UPDATE_LOCATION_LIST
  )]: (state, action) => {
    const updatedLocationList = state.list.slice();
    let updateIndex = -1;

    for (let i = 0; i < updatedLocationList.length; i++) {
      if (updatedLocationList[i].id === action.payload.id) {
        updateIndex = i;
        break;
      }
    }
    if (updateIndex > -1) {
      updatedLocationList.splice(updateIndex, 1, action.payload);
    }

    return {
      ...state,
      list: updatedLocationList,
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.SET_SELECTED_LOCATION
  )]: (state, action) => {
    return {
      ...state,
      selectedLocation: action.payload,
      showSaveLocationScreen: false,
      showShiftsScreen: false,
      showServicePricesScreen: false,

      // Delivery Settings state
      showDeliverySettingsScreen: false,
      deliverySettings: {},
      deliveryWizard: "",
      editDeliverySettingsScreenType: null,

      activeLocationServices: initialState.activeLocationServices,
      servicesError: initialState.servicesError,
      locationDetailsError: "",
      fullServiceError: "",
      showAddTaxScreen: false,
      taxErrorMessage: "",
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.SHOW_CREATE_SCREEN
  )]: (state) => {
    return {
      ...state,
      showSaveLocationScreen: true,
      isEdit: initialState.isEdit,
      selectedLocation: initialState.selectedLocation,
      showShiftsScreen: initialState.showShiftsScreen,
      showServicePricesScreen: false,
      showAddTaxScreen: false,
      taxErrorMessage: "",
      newTaxRateWhileLocationCreation: null,
      locationDetailsError: "",
      deliverySettings: {},
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.SHOW_EDIT_SCREEN
  )]: (state) => {
    return {
      ...state,
      showSaveLocationScreen: true,
      isEdit: true,
      showShiftsScreen: initialState.showShiftsScreen,
      showServicePricesScreen: false,
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.SET_LOCATION_LIST_PROGRESS
  )]: (state, action) => {
    return {
      ...state,
      isLocationCallInProgress: action.payload,
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.RESET_FULL_LOCATION
  )]: () => {
    return Object.assign({}, initialState);
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.SET_SHIFTS_VISIBILITY
  )]: (state, action) => {
    return {
      ...state,
      showShiftsScreen: action.payload,
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.SET_ACTIVE_SHIFT_TAB
  )]: (state, action) => {
    return {
      ...state,
      selectedShiftIndex: action.payload,
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.TOGGLE_SHIFT_CHECKBOX
  )]: (state, action) => {
    const shiftsData = {...state.selectedLocationShiftsData};

    const shifts = shiftsData.shifts.slice();

    shifts[action.payload.shiftIndex].timings[action.payload.dayIndex].isActive =
      !shifts[action.payload.shiftIndex].timings[action.payload.dayIndex].isActive;

    return {
      ...state,
      selectedLocationShiftsData: {...shiftsData, shifts},
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.SET_SHIFT_TIME
  )]: (state, action) => {
    const shiftsData = {...state.selectedLocationShiftsData};

    const {shiftIndex, dayIndex, key, value} = action.payload;
    const shifts = shiftsData.shifts.slice();

    shifts[shiftIndex].timings[dayIndex][key] = value;

    return {
      ...state,
      selectedLocationShiftsData: {...shiftsData, shifts},
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.APPLY_TIMING_TO_ALL
  )]: (state, action) => {
    const shiftsData = {...state.selectedLocationShiftsData};

    const shifts = shiftsData.shifts.slice();
    const shift = shifts[action.payload.shiftIndex];

    shift.timings = action.payload.newTimings;

    shifts[action.payload.shiftIndex] = shift;

    return {
      ...state,
      selectedLocationShiftsData: {...shiftsData, shifts},
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.RESET_SHIFT
  )]: (state, action) => {
    const shiftIndex = action.payload.shiftIndex;
    const shiftsData = {...state.selectedLocationShiftsData};
    const shifts = shiftsData.shifts.slice();
    if (!shifts[shiftIndex]) {
      return {
        ...state,
      };
    }
    shifts[shiftIndex] = _.cloneDeep(
      state.unTouchedSelectedLocationShiftsData.shifts[shiftIndex]
    );

    return {
      ...state,
      selectedLocationShiftsData: {...shiftsData, shifts},
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.RESET_ALL_SHIFTS_CHANGES
  )]: (state) => {
    const shiftsData = {...state.selectedLocationShiftsData};
    const shifts = state.unTouchedSelectedLocationShiftsData.shifts.slice();

    return {
      ...state,
      selectedLocationShiftsData: {...shiftsData, shifts},
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.SET_SHIFTS_CALL_IN_PROGRESS
  )]: (state, action) => {
    return {
      ...state,
      isShiftsCallInProgress: action.payload,
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.SET_SHIFTS
  )]: (state, action) => {
    const shiftsResponse = {...action.payload};

    shiftsResponse.shifts = curateShiftsAndTimings(shiftsResponse.shifts || [], {
      overlapping: true,
    });

    return {
      ...state,
      selectedLocationShiftsData: _.cloneDeep(shiftsResponse),
      unTouchedSelectedLocationShiftsData: _.cloneDeep(shiftsResponse),
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.RESET_SHIFTS_TO_INIT
  )]: (state, action) => {
    return {
      ...state,
      showShiftsScreen: action.payload,
      isShiftsCallInProgress: initialState.isShiftsCallInProgress,
      selectedLocationShiftsData: initialState.selectedLocationShiftsData,
      unTouchedSelectedLocationShiftsData:
        initialState.unTouchedSelectedLocationShiftsData,
      selectedShiftIndex: initialState.selectedShiftIndex,
      shiftsError: initialState.shiftsError,
      shiftsUpdateOrCreateError: initialState.shiftsUpdateOrCreateError,
    };
  },
  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.SET_SHIFTS_ERROR
  )]: (state, action) => {
    return {
      ...state,
      shiftsError: action.payload,
    };
  },
  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.SET_SHIFTS_UPDATE_OR_CREATE_ERROR
  )]: (state, action) => {
    return {
      ...state,
      shiftsUpdateOrCreateError: action.payload,
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.SET_DISTRICTS
  )]: (state, action) => {
    return {
      ...state,
      districts: action.payload,
    };
  },
  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.SET_DISTRICTS_CALL_IN_PROGRESS
  )]: (state, action) => {
    return {
      ...state,
      districtsCallInProgress: action.payload,
    };
  },
  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.SET_REGIONS
  )]: (state, action) => {
    return {
      ...state,
      regions: action.payload,
    };
  },
  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.SET_DISTRICTS_CALL_IN_PROGRESS
  )]: (state, action) => {
    return {
      ...state,
      regionsCallInProgress: action.payload,
    };
  },
  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.SET_REFRESH_LOCATIONS
  )]: (state, action) => {
    return {
      ...state,
      refreshLocations: action.payload,
    };
  },
  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations
      .SET_LOCATIONS_AND_REGIONS_WITHOUT_HUB
  )]: (state, action) => {
    return {
      ...state,
      ...action.payload,
    };
  },
  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.SET_WITHOUT_HUB_CALL_IN_PROGRESS
  )]: (state, action) => {
    return {
      ...state,
      isWithOutHubCallInProgress: action.payload,
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.SHOW_SERVICE_PRICE_SCREEN
  )]: (state) => {
    return {
      ...state,
      showServicePricesScreen: true,
      fullServiceError: "",
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.SET_DELIVERY_SETTINGS_CALL_PROGRESS
  )]: (state, action) => {
    return {
      ...state,
      deliverySettingsApiError: "",
      deliverySettingsLoading: action.payload,
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.SET_DELIVERY_SETTINGS
  )]: (state, action) => {
    return {
      ...state,
      deliverySettings: action.payload,
      deliverySettingsApiError: "",
      deliverySettingsLoading: false,
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.SET_DELIVERY_SETTINGS_ERROR
  )]: (state, action) => {
    return {
      ...state,
      deliverySettingsApiError: action.payload,
      deliverySettingsLoading: false,
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.SHOW_DELIVERY_SETTINGS_SCREEN
  )]: (state) => {
    return {
      ...state,
      showServicePricesScreen: false,
      showShiftsScreen: false,
      showProductPricesScreen: false,
      showDeliverySettingsScreen: true,
      fullServiceError: "",
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.SHOW_DETAILS_SCREEN
  )]: (state) => {
    return {
      ...state,
      showServicePricesScreen: false,
      showShiftsScreen: false,
      showProductPricesScreen: false,
      showDeliverySettingsScreen: false,
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.SHOW_PRODUCT_PRICES_SCREEN
  )]: (state) => {
    return {
      ...state,
      showServicePricesScreen: false,
      showShiftsScreen: false,
      showProductPricesScreen: true,
      fullServiceError: "",
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.SET_SERVICES_CALL_ERROR
  )]: (state, action) => {
    return {
      ...state,
      servicesError: action.payload,
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.SET_SERVICES_CALL_LOADING
  )]: (state, action) => {
    return {
      ...state,
      isServiceCallInProgress: action.payload,
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.SET_ACTIVE_LOCATION_SERVICES
  )]: (state, action) => {
    return {
      ...state,
      activeLocationServices: action.payload,
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.UPDATE_SERVICE_PRICE
  )]: (state, action) => {
    const {activeLocationServices} = state;
    const {categoryId, serviceId, field, value} = action.payload;
    const categoryIndex = activeLocationServices.findIndex(
      (category) => categoryId === category.id
    );
    const category = activeLocationServices[categoryIndex];
    const serviceIndex = category.services.findIndex(
      (service) => service.id === serviceId
    );
    const service = category.services[serviceIndex];
    const price = service.prices[0]; // Assuming exactly one prices comes in this array
    price[field] = value;
    activeLocationServices[categoryIndex].services[serviceIndex].prices[0] = {...price};

    return {
      ...state,
      activeLocationServices: [...activeLocationServices],
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.CLOSE_SERVICE_PRICE
  )]: (state) => {
    return {
      ...state,
      showServicePricesScreen: false,
      activeLocationServices: initialState.activeLocationServices,
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.SET_LOCATION_DETAILS_LOADING
  )]: (state, action) => {
    return {
      ...state,
      isLocationDetailsLoading: action.payload,
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.SET_LOCATION_DETAILS_ERROR
  )]: (state, action) => {
    return {
      ...state,
      locationDetailsError: action.payload,
      isLocationDetailsLoading: false,
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.REFRESH_ACTIVE_LOCATION_DETAILS
  )]: (state, action) => {
    return {
      ...state,
      refreshLocationDetails: action.payload,
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.SET_FULL_SERVICE_LIST_ERROR
  )]: (state, action) => {
    return {
      ...state,
      fullServiceError: action.payload,
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations
      .SET_SERVICES_AND_PRODUCTS_ACTIVE_TAB
  )]: (state, action) => {
    return {
      ...state,
      activeServicesAndProductsTab: action.payload,
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.SET_SERVICE_PRICES_UPDATE_ERROR
  )]: (state, action) => {
    return {
      ...state,
      servicePriceUpdateError: action.payload,
    };
  },

  // Fetch Tax Rates List
  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.SET_TAXES_LIST
  )]: (state, action) => {
    const taxRatesList = sortAscByName(action.payload);
    return {
      ...state,
      taxRatesList,
    };
  },

  // Add tax rate to location

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.TAX_SETTINGS_UPDATE_IN_PROGRESS
  )]: (state, action) => {
    return {
      ...state,
      taxUpdateCallInProgress: action.payload,
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations
      .PROCESSING_SETTINGS_UPDATE_IN_PROGRESS
  )]: (state, action) => {
    return {
      ...state,
      processingUpdateCallInProgress: action.payload,
    };
  },

  // ESD Registration Screen

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.SHOW_ESD_REGISTRATION_SCREEN
  )]: (state, action) => {
    return {
      ...state,
      showEsdRegistrationScreen: action.payload,
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.SET_ESD_VALUES
  )]: (state, action) => {
    const selectedLocation = {...state.selectedLocation};
    const esdReader = selectedLocation.esdReader;
    esdReader[action.payload.field] = action.payload.value;
    return {
      ...state,
      esdReader,
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.SET_ESD_ERROR
  )]: (state, action) => {
    return {
      ...state,
      esdErrorMessage: action.payload,
    };
  },

  // Reset Password Screen

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.SET_SHOW_RESET_PASSWORD_SCREEN
  )]: (state, action) => {
    // Whenever reset password screen is called or closed,
    // reset the entire data
    return {
      ...state,
      showResetPasswordScreen: action.payload,
      resetPasswordApiError: "",
      resetPasswordLoading: false,
    };
  },
  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.RESET_PASSWORD_API_STARTED
  )]: (state) => {
    return {
      ...state,
      resetPasswordApiError: "",
      resetPasswordLoading: true,
    };
  },
  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.RESET_PASSWORD_API_SUCCESS
  )]: (state) => {
    return {
      ...state,
      resetPasswordApiError: "",
      resetPasswordLoading: false,
    };
  },
  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.RESET_PASSWORD_API_FAILURE
  )]: (state, action) => {
    return {
      ...state,
      resetPasswordApiError: action.payload,
      resetPasswordLoading: false,
    };
  },

  //Checked In Employee Screen

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations
      .SET_SHOW_CHECKED_IN_EMPLOYEES_SCREEN
  )]: (state, action) => {
    return {
      ...state,
      showCheckedInEmployees: action.payload,
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.FETCH_CHECKED_IN_EMPLOYEES_STARTED
  )]: (state, action) => {
    return {
      ...state,
      fetchCheckedInEmployeesLoading: action.payload,
      fetchCheckedInEmployeesApiError: "",
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.FETCH_CHECKED_IN_EMPLOYEES_SUCCESS
  )]: (state, action) => {
    return {
      ...state,
      showCheckedInEmployees: true,
      checkedInEmployees: action.payload,
      fetchCheckedInEmployeesApiError: "",
      fetchCheckedInEmployeesLoading: false,
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.FETCH_CHECKED_IN_EMPLOYEES_FAILURE
  )]: (state, action) => {
    return {
      ...state,
      fetchCheckedInEmployeesApiError: action.payload,
      fetchCheckedInEmployeesLoading: false,
    };
  },

  //Store Closures Screen

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.SET_SHOW_STORE_CLOSURES_SCREEN
  )]: (state, action) => {
    return {
      ...state,
      showStoreClosuresScreen: action.payload,
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.FETCH_STORE_CLOSURES_STARTED
  )]: (state) => {
    return {
      ...state,
      storeClosuresApiLoading: true,
      storeClosuresApiError: "",
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.FETCH_STORE_CLOSURES_SUCCESS
  )]: (state, action) => {
    return {
      ...state,
      storeClosures: action.payload,
      storeClosuresApiError: "",
      storeClosuresApiLoading: false,
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.FETCH_STORE_CLOSURES_FAILURE
  )]: (state, action) => {
    return {
      ...state,
      storeClosuresApiError: action.payload,
      storeClosuresApiLoading: false,
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.CREATE_STORE_CLOSURES_STARTED
  )]: (state) => {
    return {
      ...state,
      storeClosuresApiLoading: true,
      storeClosuresApiError: "",
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.CREATE_STORE_CLOSURES_SUCCESS
  )]: (state, action) => {
    return {
      ...state,
      storeClosures: {
        ...state.storeClosures,
        closures: [...state.storeClosures.closures, action.payload],
      },
      storeClosuresApiError: "",
      storeClosuresApiLoading: false,
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.CREATE_STORE_CLOSURES_FAILURE
  )]: (state, action) => {
    return {
      ...state,
      storeClosuresApiError: action.payload,
      storeClosuresApiLoading: false,
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.DELETE_STORE_CLOSURES_STARTED
  )]: (state) => {
    return {
      ...state,
      storeClosuresApiLoading: true,
      storeClosuresApiError: "",
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.DELETE_STORE_CLOSURES_SUCCESS
  )]: (state, action) => {
    const deletedClosureId = action.payload;
    const actualStoreClosures = state.storeClosures.closures;
    const filteredStoreClosures = actualStoreClosures.filter(
      (closure) => closure.id !== deletedClosureId
    );
    return {
      ...state,
      storeClosures: {...state.storeClosures, closures: filteredStoreClosures},
      storeClosuresApiError: "",
      storeClosuresApiLoading: false,
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.DELETE_STORE_CLOSURES_FAILURE
  )]: (state, action) => {
    return {
      ...state,
      storeClosuresApiError: action.payload,
      storeClosuresApiLoading: false,
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.PRE_CREATE_STORE_CLOSURES_STARTED
  )]: (state) => {
    return {
      ...state,
      storeClosuresApiLoading: true,
      storeClosuresApiError: "",
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.PRE_CREATE_STORE_CLOSURES_SUCCESS
  )]: (state, action) => {
    return {
      ...state,
      impactedOrders: action.payload,
      storeClosuresApiError: "",
      storeClosuresApiLoading: false,
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.PRE_CREATE_STORE_CLOSURES_FAILURE
  )]: (state, action) => {
    return {
      ...state,
      storeClosuresApiError: action.payload,
      storeClosuresApiLoading: false,
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.SET_IMPACTED_ORDERS
  )]: (state, action) => {
    return {
      ...state,
      impactedOrders: action.payload,
    };
  },

  // Add Tax Rate Screen

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.SHOW_HIDE_ADD_TAXRATE_SCREEN
  )]: (state, action) => {
    return {
      ...state,
      showAddTaxScreen: action.payload,
    };
  },
  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.ADD_TAX_RATE_API_STARTED
  )]: (state) => {
    return {
      ...state,
      newTaxRateCallInprogress: true,
      taxErrorMessage: "",
    };
  },
  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.ADD_TAX_RATE_API_SUCCESS
  )]: (state) => {
    return {
      ...state,
      showAddTaxScreen: false,
      newTaxRateCallInprogress: false,
      taxErrorMessage: "",
    };
  },
  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.APPEND_TO_TAX_LIST
  )]: (state, action) => {
    const updatedTaxList = state.taxRatesList.slice();
    const {selectedLocation} = state;
    if (selectedLocation) {
      selectedLocation.taxRate = {...action.payload};
    }
    updatedTaxList.push(action.payload);

    return {
      ...state,
      taxRatesList: sortAscByName(updatedTaxList),
      selectedLocation,
    };
  },
  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.ADD_TAX_RATE_API_FAILURE
  )]: (state, action) => {
    return {
      ...state,
      taxErrorMessage: action.payload,
      newTaxRateCallInprogress: false,
    };
  },
  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.EXIT_ADD_TAX_RATE_SCREEN
  )]: (state) => {
    return {
      ...state,
      showAddTaxScreen: false,
      newTaxRateCallInprogress: false,
      taxErrorMessage: initialState.taxErrorMessage,
    };
  },

  // Add Location Screens

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.CHANGE_ADD_LOCATION_STEP
  )]: (state, action) => {
    return {
      ...state,
      addLocationStep: action.payload,
    };
  },
  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.CLOSE_ADD_LOCATION_SCREEN
  )]: (state) => {
    return {
      ...state,
      selectedLocation: null,
      isEdit: false,
      saveLocationCallInProgress: false,
      showSaveLocationScreen: false,
      addLocationStep: 1,
      errorMessage: "",
      newTaxRateWhileLocationCreation: null,
    };
  },
  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations
      .SET_NEW_TAX_RATE_WHILE_LOCATION_CREATION
  )]: (state, action) => {
    return {
      ...state,
      newTaxRateWhileLocationCreation: action.payload,
    };
  },
  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.CLOSE_EDIT_LOCATION_SCREEN
  )]: (state) => {
    return {
      ...state,
      isEdit: false,
      saveLocationCallInProgress: false,
      showSaveLocationScreen: false,
      errorMessage: "",
    };
  },
  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.SAVE_LOCATION_API_STARTED
  )]: (state) => {
    return {
      ...state,
      saveLocationCallInProgress: true,
      showFullPageError: false,
      errorMessage: "",
    };
  },
  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.SAVE_LOCATION_API_SUCCESS
  )]: (state) => {
    return {
      ...state,
      saveLocationCallInProgress: false,
      showSaveLocationScreen: false,
      isEdit: false,
      addLocationStep: 1,
      showFullPageError: false,
      errorMessage: "",
    };
  },
  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.SAVE_LOCATION_API_FAILURE
  )]: (state, action) => {
    return {
      ...state,
      saveLocationCallInProgress: false,
      showFullPageError: false,
      errorMessage: action.payload,
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.SELECTED_LOCATION_FIELD_CHANGE
  )]: (state, action) => {
    return {
      ...state,
      selectedLocation: {
        ...state.selectedLocation,
        [action.payload.field]: action.payload.value,
      },
    };
  },

  // Delivery Settings

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.SET_DELIVERY_WIZARD_TYPE
  )]: (state, action) => {
    return {
      ...state,
      showDeliverySettingsScreen: action.payload
        ? state.showDeliverySettingsScreen
        : true,
      deliveryWizard: action.payload,
      editDeliverySettingsScreenType: null,
    };
  },
  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations
      .SET_EDIT_DELIVERY_SETTINGS_SCREEN_TYPE
  )]: (state, action) => {
    return {
      ...state,
      showDeliverySettingsScreen: action.payload
        ? state.showDeliverySettingsScreen
        : true,
      deliveryWizard: null,
      editDeliverySettingsScreenType: action.payload,
    };
  },
  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations.SET_DETAILS_UPDATE_IN_PROGRESS
  )]: (state, action) => {
    return {
      ...state,
      isDetailsCallInProgress: action.payload,
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations
      .SET_ONDEMAND_DELIVERY_SETTINGS_ACTIVE_TOGGLE
  )]: (state, action) => {
    return {
      ...state,
      deliverySettings: {
        ...state.deliverySettings,
        onDemandDeliverySettings: {
          ...state.deliverySettings.onDemandDeliverySettings,
          active: action.payload,
        },
      },
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations
      .SET_OWNDRIVER_DELIVERY_SETTINGS_ACTIVE_TOGGLE
  )]: (state, action) => {
    return {
      ...state,
      deliverySettings: {
        ...state.deliverySettings,
        ownDriverDeliverySettings: {
          ...state.deliverySettings.ownDriverDeliverySettings,
          active: action.payload,
        },
      },
    };
  },

  [BoGlobalSettingsNamespacer(
    actionTypes.businessOwner.globalSettings.locations
      .TOGGLE_SERVICE_MULTISELECT_FOR_DELIVERY
  )]: (state) => {
    return {
      ...state,
      deliverySettings: {
        ...state.deliverySettings,
        generalDeliverySettings: {
          ...state.deliverySettings.generalDeliverySettings,
          serviceMultiselectEnabled:
            !state.deliverySettings.generalDeliverySettings.serviceMultiselectEnabled,
        },
      },
    };
  },
};

//fullServiceError

export default createReducer(initialState, handlers, ["BUSINESS_OWNER_GS_LOCATIONS"]);
