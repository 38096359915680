export enum MessengerFilterTabsNames {
  All = "All",
  Unread = "Unread",
}

export const enum MessageType {
  MARKETING = "Marketing",
}

export const enum NotificationMedium {
  SMS = "sms",
  EMAIL = "email",
}

export const enum MessageDirection {
  INBOUND = "Inbound",
  OUTBOUND = "Outbound",
}

export const enum MessengerQueryKeys {
  CONVERSATIONS_LIST = "conversations-list",
  CUSTOMER_MESSAGES = "customer-messages",
  MARK_CONVERSATION_AS_READ = "mark-conversation-as-read",
  SEND_SMS_MESSAGE = "send-sms-message",
  UNREAD_CONVERSATIONS_COUNT = "unread-conversations-count",
  SEND_BULK_MESSAGE = "send-bulk-message",
}

export const PER_PAGE_SIZE_LIMIT = 50;
export const ONE_MESSAGE_CHARACTERS_LIMIT = 160;
export const MESSENGER_DEFAULT_PAGE_NUMBER = 1;
export const MARK_CONVERSATION_AS_READ_DELAY_MS = 2000;
