export const usaStateList = [
  "Alabama",
  "Alaska",
  "American Samoa",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District Of Columbia",
  "Federated States Of Micronesia",
  "Florida",
  "Georgia",
  "Guam",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Marshall Islands",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Northern Mariana Islands",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Palau",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virgin Islands",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

export const usaStateAbbreviationsMap = {
  Alabama: "AL",
  Alaska: "AK",
  "American Samoa": "AS",
  Arizona: "AZ",
  Arkansas: "AR",
  California: "CA",
  Colorado: "CO",
  Connecticut: "CT",
  Delaware: "DE",
  "District Of Columbia": "DC",
  "Federated States Of Micronesia": "FM",
  Florida: "FL",
  Georgia: "GA",
  Guam: "GU",
  Hawaii: "HI",
  Idaho: "ID",
  Illinois: "IL",
  Indiana: "IN",
  Iowa: "IA",
  Kansas: "KS",
  Kentucky: "KY",
  Louisiana: "LA",
  Maine: "ME",
  "Marshall Islands": "MH",
  Maryland: "MD",
  Massachusetts: "MA",
  Michigan: "MI",
  Minnesota: "MN",
  Mississippi: "MS",
  Missouri: "MO",
  Montana: "MT",
  Nebraska: "NE",
  Nevada: "NV",
  "New Hampshire": "NH",
  "New Jersey": "NJ",
  "New Mexico": "NM",
  "New York": "NY",
  "North Carolina": "NC",
  "North Dakota": "ND",
  "Northern Mariana Islands": "MP",
  Ohio: "OH",
  Oklahoma: "OK",
  Oregon: "OR",
  Palau: "PW",
  Pennsylvania: "PA",
  "Puerto Rico": "PR",
  "Rhode Island": "RI",
  "South Carolina": "SC",
  "South Dakota": "SD",
  Tennessee: "TN",
  Texas: "TX",
  Utah: "UT",
  Vermont: "VT",
  "Virgin Islands": "VI",
  Virginia: "VA",
  Washington: "WA",
  "West Virginia": "WV",
  Wisconsin: "WI",
  Wyoming: "WY",
};

export const LOCAL_CURRENCY = "USD";
export const LOCALE = "en-US";

export const run_machine_wash_type = {
  TECHNICAL: "technical",
  CUSTOMER_SERVICE: "customerService",
};

export const servicesAndProductsTabValues = {
  LAUNDRY: "laundry_services",
  FIXED_PRICE: "fixed_price_services",
  PER_POUND: "lb_services",
  PRODUCTS: "products",
  DRY_CLEANING: "dry_cleaning_services",
};

export const WEEK_DAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const SHORT_WEEK_DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const ORDER_TYPES = {
  residential: "RESIDENTIAL",
  service: "SERVICE",
  online: "ONLINE",
  inventory: "INVENTORY",
};

export const ROLES = {
  owner: "owner",
  admin: "admin",
  manager: "manager",
  installer: "installer",
  driver: "Driver",
};

export const RoleNameMapping = {
  employee: "Employee",
  businessOwner: "Business Owner",
  businessAdmin: "Business Admin",
  businessManager: "Business Manager",
  customer: "Customer",
  driver: "Driver",
};

export const TURN_STATUS = {
  CREATED: "Turn enabled",
  STARTED: "Turn in process",
  ENABLED: "Turn enabled",
  COMPLETED: "Turn complete",
};

export const ShiftTypes = {
  SHIFT: "SHIFT",
  OWN_DELIVERY: "OWN_DELIVERY",
  CENTS_DELIVERY: "CENTS_DELIVERY",
};

export const SUBSCRIPTION_INTERVAL_DISPLAY = {
  1: "Weekly",
  2: "Every 2 weeks",
  3: "Every 3 weeks",
  4: "Every 4 weeks",
};

export const SERVICE_TYPES = [
  {label: "Pickup & Delivery", value: "ALL"},
  {label: "Pickup", value: "PICKUP"},
  {label: "Delivery", value: "RETURN"},
];

export const PREFERENCES_SECTION_TITLE_LENGTH = 80;
export const PREFERENCES_OPTION_LENGTH = 80;

export const WASH_AND_FOLD_SUBCATEGORY = "PER_POUND";

export const HELP_BUTTON_ID = "helpButton"; // is used to run Intercom messenger

export const INTERCOM_COMPANY_CUSTOM_ATTRIBUTES = {businessId: "Business ID"};

export const MY_STORES_TITLE = "My Stores";

export const PRICING_TYPES = {
  FIXED_PRICE: "FIXED_PRICE",
  PER_POUND: "PER_POUND",
};

export const PRICE_UNIT_LABELS = {
  [PRICING_TYPES.FIXED_PRICE]: "unit",
  [PRICING_TYPES.PER_POUND]: "lb",
};

export enum TIP_SETTINGS_TYPES {
  IN_STORE_TIPPING = "inStoreTipSettings",
  ONLINE_TIPPING = "onlineTipSettings",
}

export enum TIP_TYPES {
  PERCENTAGE = "PERCENTAGE",
  DOLLAR_AMOUNT = "DOLLAR_AMOUNT",
}

export const OPTS_PHONE_NUMBER = "321-248-7171";

export const USA_DATE_FORMAT = "MM/DD/YYYY";
