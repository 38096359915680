import * as Sentry from "@sentry/react";
import React, {lazy, Suspense, useCallback, useEffect, useState} from "react";
import {Redirect, Route, BrowserRouter as Router, Switch} from "react-router-dom";
import {useIntercom} from "react-use-intercom";

import BlockingLoader from "~components/commons/blocking-loader/blocking-loader";
import PageNotFound from "~components/page-not-found";
import RouteWithLDUser from "~components/RouteWithLDUser/RouteWithLDUser";

import {useSessionStorage} from "~hooks/useSessionStorage";

import {getAccountInfo} from "~api/business-owner/account";
import {ROLES} from "~constants";
import {GUEST_ROUTES} from "~constants/routes";
import BoRouteAuthorization from "~hocs/BoRouteAuthorization";
import {SessionState} from "~reducers/session";
import FullStory from "~third-party/fullstory";
import {setSurvicateTraits} from "~third-party/survicate";
import {SESSION_ENV_KEY} from "~utils/config";
import {isInternalEmail} from "~utils/email";
import {getIntercomBootData, getParsedLocalStorageData} from "~utils/functions";

// Lazy loads
const BODashboard = lazy(() => import("./containers/bo-dashboard"));
const BOGlobalSettings = lazy(() => import("./containers/bo-gs-dashboard"));
const PasswordReset = lazy(() => import("./containers/password-reset"));
const Login = lazy(() => import("./containers/login"));
const ForgotPassword = lazy(() => import("./containers/forgot-password"));
const BOMessenger = lazy(() => import("./containers/bo-messenger"));

const Spinner = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100dvh",
        width: `100%`,
      }}
    >
      <span>Loading</span>
    </div>
  );
};

const SURVICATE_READY_EVENT = "SurvicateReady";

const BusinessOwnerRoutes = ({
  initialLink,
  setInitialLink,
}: {
  initialLink: string;
  setInitialLink: (newInitialLink: string) => void;
}) => {
  return (
    <Suspense fallback={<BlockingLoader />}>
      <Switch>
        <RouteWithLDUser
          path="/dashboard"
          component={BoRouteAuthorization(
            [ROLES.owner, ROLES.admin, ROLES.manager, ROLES.installer],
            BODashboard
          )}
        />

        <RouteWithLDUser
          path="/global-settings"
          component={BoRouteAuthorization([ROLES.owner, ROLES.admin], BOGlobalSettings)}
        />

        <RouteWithLDUser
          path="/messenger"
          component={BoRouteAuthorization(
            [ROLES.owner, ROLES.admin, ROLES.manager],
            BOMessenger
          )}
        />

        <Route path="/password-reset" component={PasswordReset} exact />
        <Route
          path="/login"
          render={() => {
            return <Redirect to="/" />;
          }}
          exact
        />
        <Route
          path="/"
          render={() => {
            if (initialLink) {
              setInitialLink("");
              sessionStorage.removeItem("initialLink");
              return <Redirect to={initialLink} />;
            }
            return <Redirect to="/dashboard" />;
          }}
          exact
        />
        <Route path="*" component={PageNotFound} exact />
      </Switch>
    </Suspense>
  );
};

const GuestRoutes = () => {
  return (
    <Suspense fallback={<BlockingLoader />}>
      <Switch>
        <Route path={GUEST_ROUTES.login} component={Login} exact />
        <Route path={GUEST_ROUTES.resetPassword} component={PasswordReset} exact />
        <Route path={GUEST_ROUTES.forgotPassword} component={ForgotPassword} exact />

        <Route
          path={GUEST_ROUTES.default}
          render={() => {
            return <Redirect to={GUEST_ROUTES.login} />;
          }}
        />
        <Route path="*" component={PageNotFound} exact />
      </Switch>
    </Suspense>
  );
};

const Routes = ({
  session,
  onSetSession,
}: {
  session: SessionState;
  onSetSession: (newSession: SessionState) => void;
}) => {
  const [isMounted, setIsMounted] = useState(false);
  const {boot} = useIntercom();
  const {isLoggedIn} = session;
  const [initialLink, setInitialLink] = useSessionStorage("initialLink", "");

  const bootIntercom = useCallback(
    async (session) => {
      if (!session.uuid || !session.intercomHash) {
        //uuid/userHash might not be set in local storage if user hasn't done login for a long time, in this case request user info from server

        try {
          const {
            data: {email, uuid, firstName, lastName, intercomHash, business},
          } = await getAccountInfo();

          boot(
            getIntercomBootData({
              email,
              uuid,
              firstName,
              lastName,
              intercomHash,
              business,
            })
          );
          onSetSession({
            ...session,
            email,
            firstName,
            lastName,
            uuid,
            intercomHash,
            business,
          });
        } catch (error) {
          Sentry.captureException(error);
        }
      } else {
        const {email, uuid, firstName, lastName, intercomHash, business} = session;

        boot(
          getIntercomBootData({
            email,
            uuid,
            firstName,
            lastName,
            intercomHash,
            business,
          })
        );
      }
    },
    [onSetSession, boot]
  );

  useEffect(
    function identifyUserAndBusiness() {
      const {business, uuid, email, firstName, lastName} = session ?? {};
      if (uuid) {
        // the uuid on the session is the user. if this is present, we can assume the other user attributes are present on the session

        FullStory.setUserVars({
          email,
          // because of our impersonation system, internal users need more specific keys to prevent the merging of sessions from different businesses
          // for external users, we still want to only use `uuid` so that it matches their id in intercom
          uid: email && isInternalEmail(email) ? `${uuid}:${business?.id}` : uuid,
          displayName: `${firstName} ${lastName}`,
          businessId: business?.id,
          businessName: business?.name,
        });

        const setTraits = () => {
          setSurvicateTraits({
            email,
            uid: uuid,
            first_name: firstName,
            last_name: lastName,
            businessId: business?.id,
            businessName: business?.name,
            businessCreatedAt: business?.createdAt,
          });
        };
        if (window._sva) {
          // if the survicate script has already been loaded, set the traits
          setTraits();
        } else {
          // if the script has not been loaded, this listener will be triggered when it is
          // THEN we can set the traits
          window.addEventListener(SURVICATE_READY_EVENT, setTraits);
          return () => {
            window.removeEventListener(SURVICATE_READY_EVENT, setTraits);
          };
        }
      }
    },
    [session]
  );

  useEffect(() => {
    const isLinkMoveToGuestRoute = Object.values(GUEST_ROUTES).includes(
      window.location.pathname
    );

    if (isLinkMoveToGuestRoute) {
      return;
    }

    const link = `${window.location.pathname}${window.location.search}`;
    setInitialLink(link);
  }, [setInitialLink]);

  useEffect(() => {
    setIsMounted(true);

    const {
      token,
      userId,
      teamMemberId,
      roleName,
      firstName,
      lastName,
      email,
      business,
      uuid,
      intercomHash,
    } = getParsedLocalStorageData(SESSION_ENV_KEY);
    const session: SessionState = {
      isLoggedIn: !!token,
    };

    if (session.isLoggedIn) {
      session.token = token;
      session.userId = userId;
      session.teamMemberId = teamMemberId;
      session.roleName = roleName;
      session.firstName = firstName;
      session.lastName = lastName;
      session.email = email;
      session.intercomHash = intercomHash;
      session.business = business;
      session.uuid = uuid;

      onSetSession(session);
      bootIntercom(session);
    } else {
      onSetSession(session);
    }
  }, [onSetSession, bootIntercom]);

  const getRoutes = () => {
    if (!isLoggedIn) {
      return <GuestRoutes />;
    }

    return (
      <BusinessOwnerRoutes initialLink={initialLink} setInitialLink={setInitialLink} />
    );
  };

  return (
    <>
      <Router>{isMounted ? <>{getRoutes()}</> : <Spinner />}</Router>
    </>
  );
};

export default Routes;
