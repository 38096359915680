import axios from "axios";

import {GUEST_ROUTES} from "~constants/routes";
import {ORIGIN_TYPES} from "~constants/statuses/originTypes";
import {BASE_URL} from "~utils/config";
import {SESSION_ENV_KEY} from "~utils/config";
import {getParsedLocalStorageData} from "~utils/functions";

const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    config.headers = {
      fwdorigin: ORIGIN_TYPES.BUSINESS_MANAGER,
    };

    // https://github.com/bmuenzenmeyer/axios-1.0.0-migration-guide/issues/7
    const authtoken = getParsedLocalStorageData(SESSION_ENV_KEY)?.token;
    if (authtoken) {
      config.headers.authtoken = authtoken;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      localStorage.removeItem(SESSION_ENV_KEY);
      if (!Object.values(GUEST_ROUTES).includes(window.location.pathname)) {
        window.location.href = window.location.origin;
      }
    }
    return Promise.reject(error);
  }
);

export const getCancelSource = () => axios.CancelToken.source();

export default axiosInstance;
