import type {IconProp} from "@fortawesome/fontawesome-svg-core";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {type CSSProperties} from "react";

interface BlockingLoaderProps {
  className?: string;
  style?: Record<string, string | number>;
  error?: string;
  zIndex?: CSSProperties["zIndex"];
  position?: CSSProperties["position"];
}

const BlockingLoader: React.FC<BlockingLoaderProps> = ({style, ...props}) => {
  const {zIndex, position} = props;
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        position: position ?? "absolute",
        alignItems: "center",
        justifyContent: "center",
        background: props.error ? "rgba(255,255,255,0.8)" : "rgba(255,255,255,0.5)",
        zIndex: zIndex ?? "50",
        ...(style ?? {}),
      }}
      {...props}
    >
      {props.error ? (
        <p className="error-message mr-3 ml-3 text-center">{props.error}</p>
      ) : (
        <FontAwesomeIcon icon={faSpinner as IconProp} spin />
      )}
    </div>
  );
};

export default BlockingLoader;
